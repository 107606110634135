import * as React from 'react';
import * as style from './StickyBottomLayout.css';

export interface IStickyBottomLayoutProps {
  callButton?: React.ReactNode;
}

export const StickyBottomLayout = ({ callButton }: IStickyBottomLayoutProps) => {
  return <div className={style['container']}>{callButton}</div>;
};
