import * as React from 'react';

import * as styles from './Button.css';

interface IButtonProps {
  children: React.ReactNode;
  color?: string;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
}

export function Button({ color, onClick, children }: IButtonProps) {
  return (
    <button className={styles['button']} onClick={onClick} style={{ backgroundColor: color }} type="button">
      {children}
    </button>
  );
}
