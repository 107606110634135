import * as React from 'react';
import { useDeviceType } from '@cian/ui-kit';

import { Button } from './components/Button';
import { ContactsModal } from './components/ContactsModal';
import * as styles from './PromoCallButton.css';

interface IPromoCallButtonProps {
  builderLogo?: string;
  builderName: string;
  color?: string;
  phone: string;
}

export function PromoCallButton({ color, phone, builderLogo, builderName }: IPromoCallButtonProps) {
  const [isContactsOpen, setIsContactsOpen] = React.useState(false);
  const deviceType = useDeviceType();

  const openContacts = React.useCallback(() => setIsContactsOpen(true), []);
  const closeContacts = React.useCallback(() => setIsContactsOpen(false), []);

  return (
    <>
      {deviceType === 'phone' ? (
        <a className={styles['phone-link']} href={`tel:${phone}`}>
          <Button color={color}>Позвонить застройщику</Button>
        </a>
      ) : (
        <Button onClick={openContacts} color={color}>
          Показать контакты застройщика
        </Button>
      )}
      <ContactsModal
        color={color}
        phone={phone}
        builderLogo={builderLogo}
        builderName={builderName}
        open={isContactsOpen}
        onClose={closeContacts}
      />
    </>
  );
}
