import * as React from 'react';
import { useSelector } from 'react-redux';

import { PromoCallButton } from '../../components/PromoCallButton';
import { StickyBottomLayout } from '../../components/StickyBottomLayout';
import { getPromoInfo } from '../../selectors/specialPromo';

// eslint-disable-next-line import/no-default-export
export default function PromoCallButtonContainer() {
  const promoInfo = useSelector(getPromoInfo);

  if (!promoInfo) {
    return null;
  }

  return (
    <StickyBottomLayout
      callButton={
        <PromoCallButton
          color={promoInfo.colorHex}
          phone={promoInfo.phone}
          builderName={promoInfo.name}
          builderLogo={promoInfo.logoMobile}
        />
      }
    />
  );
}
