import * as React from 'react';
import { ModalWindow } from '@cian/ui-kit';

import { formatPhone } from '../../../../utils/formatters/phone';
import * as styles from './ContactsModal.css';

interface IContactsModalProps {
  builderLogo?: string;
  builderName: string;
  color?: string;
  onClose(): void;
  open: boolean;
  phone: string;
}

export function ContactsModal({ builderLogo, builderName, color, onClose, open, phone }: IContactsModalProps) {
  return (
    <ModalWindow open={open} width="100%" onClose={onClose}>
      <div className={styles['builder-contacts']}>
        <div className={styles['builder-info']}>
          {builderLogo && <div className={styles['builder-logo']} style={{ backgroundImage: `url(${builderLogo})` }} />}
          <p className={styles['builder-name']}>{builderName}</p>
        </div>

        <a href={`tel:${phone}`} className={styles['call-button']} style={{ color, borderColor: color }}>
          {formatPhone(phone)}
        </a>
        <div className={styles['action-container']}>
          <button className={styles['close-button']} onClick={onClose} style={{ color }} type="button">
            Закрыть
          </button>
        </div>
      </div>
    </ModalWindow>
  );
}
